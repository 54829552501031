import React, { useEffect, useMemo, useState, useCallback } from "react";
import MaterialReactTable from "material-react-table";
import { Info } from "@mui/icons-material";
import PopupModal from "./Modal";
import CartPopupModal from "./CartPopupModal";
import DiscountModal from "./DiscountModal";
import { FaCartArrowDown } from "react-icons/fa";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import ImageViewer from "react-simple-image-viewer";
import { Toaster } from "react-hot-toast";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { COLUMNS } from "./columns-material";
import { FetchPartsData } from "./Api/partsApi";
import Cart from "./Cart-components/Cart";
import "./MaterialTable.css";
import handleAddToCartFun from "./Material-items/handleAddToCartFun";
import ProductPopup from "./Material-items/ProductPopup";
import caluclateFlatTotal from "./Material-functions/caluclateFlatTotal";
import caluclateDiscount from "./Material-functions/caluclateDiscount";
import caluclateMixMatch from "./Material-functions/caluclateMixMatch";

import { pink, red } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import cartSession from "./Cart-components/cartSession";
import { useDispatch, useSelector } from "react-redux";
import { initialState, setCartDetails } from "../../store/reducer/cart";
import { useNavigate } from "react-router-dom";
import SingleCartPopupModal from "./Material-items/SingleCartPopupModal";
import { fetchSavedTemplate1, postSavedTemplate } from "./Api/savedTemplateApi";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: red[900],
    "&:hover": {
      backgroundColor: alpha(red[900], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: red[900],
  },
}));
const MaterialUISwitchQuota = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink["A200"],
    "&:hover": {
      backgroundColor: alpha(pink["A200"], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: red[900],
  },
}));

const MaterialTable = ({ isVendorName }) => {
  const navigate = useNavigate();
  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const dispatch = useDispatch();
  const cartDetails = useSelector((state) => state.cart);
  const { cartList, cartGrossTotal, cartDiscount, totalCartItem } = cartDetails;

  //table state
  const [columnFilters, setColumnFilters] = useState([]);

  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const [cartItems, setCartItems] = useState([]);
  const [cartTemplate, setCartTemplate] = useState([]);

  const [iconDisplay, setIconDisplay] = useState(["none"]);

  const [barcodeValue, setBarcodeValue] = useState("");
  const [discountedProducts, setDiscountedProducts] = useState(false);
  const [quotaProducts, setQuotaProducts] = useState(false);
  const [inStockProduct, setInStockProduct] = useState(false);
  const [inStockExclusive, setInStockExclusive] = useState(false);
  const [supValue, setSupValue] = useState("");
  const [activeIngValue, setActiveIngValue] = useState("");
  const [quantityInputValue, setQuantityInputValue] = useState({});
  const [productQuantity, setProductQuantity] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({
    BARCODE: true,
    SUPNAME: false,
    DEXT_IMPORTERNAME: false,
    DEXT_BRAND: false,
    IMPORTER_DES: false,
    VENDOR_DES: false,
    SPEC14: false,
    SPEC19: false,
    SPEC1: false,
    QTYTORECEIVE: isVendorName ? true : false,
    PARTNAME: window.innerWidth > 1024 ? true : false,
    Quota: window.innerWidth > 1024 ? true : false,
    expiry: window.innerWidth > 1024 ? true : false,
    DEXT_LOWSTOCKQTY: window.innerWidth > 1024 ? true : false,
    BARCODE: window.innerWidth > 1024 ? true : false,
    VATPRICE: window.innerWidth > 1024 ? true : false,
    WSPLPRICE: window.innerWidth > 1024 ? true : false,
  });

  //image viewr
  const [currentImage, setCurrentImage] = useState("");
  const [tagetImage, setTargetImage] = useState("");
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [highlightStyle, setHighlightStyle] = useState([]);

  const openImageViewer = useCallback((index, imgSrc) => {
    setTargetImage(imgSrc);
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const handleBarcodeChange = (event) => {
    setBarcodeValue(event.target.value);
  };

  const showDiscounted = (event) => {
    setDiscountedProducts(event.target.checked);
  };

  const showQuota = (event) => {
    setQuotaProducts(event.target.checked);
  };
  const showInStock = (event) => {
    setInStockProduct(event.target.checked);
  };

  const showInStockExclusive = (event) => {
    setInStockExclusive(event.target.checked);
  };

  const handleSupplierChange = (event) => {
    setSupValue(event.target.value);
  };

  const handleActiveIngredientChange = (event) => {
    setActiveIngValue(event.target.value);
  };

  useEffect(() => {
    const savedColumnVisibility = localStorage.getItem("columnVisibility");
    if (savedColumnVisibility) {
      setColumnVisibility(JSON.parse(savedColumnVisibility));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("columnVisibility", JSON.stringify(columnVisibility));
  }, [columnVisibility]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      FetchPartsData(
        sorting,
        globalFilter,
        columnFilters,
        pagination,
        setIconDisplay,
        setData,
        setRowCount,
        setIsError,
        barcodeValue,
        supValue,
        activeIngValue,
        discountedProducts,
        quotaProducts,
        isVendorName,
        setIsLoading,
        inStockProduct,
        inStockExclusive
      );
    }, 1000);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [columnFilters, globalFilter, isVendorName]);

  useEffect(() => {
    FetchPartsData(
      sorting,
      globalFilter,
      columnFilters,
      pagination,
      setIconDisplay,
      setData,
      setRowCount,
      setIsError,
      barcodeValue,
      supValue,
      activeIngValue,
      discountedProducts,
      quotaProducts,
      isVendorName,
      setIsLoading,
      inStockProduct,
      inStockExclusive
    );
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    discountedProducts,
    quotaProducts,
    inStockProduct,
    isVendorName,
    inStockExclusive,
  ]);

  const filteredColumn = COLUMNS.filter(
    (item) => item.accessorKey !== "QTYTORECEIVE"
  );

  const columns = useMemo(() => (isVendorName ? COLUMNS : filteredColumn), []);

  const [showCart, setShowCart] = useState(false);

  const [total, setTotal] = useState(0);
  const [isLoadingCart, setIsLoadingCart] = useState(true);
  const [isRefreshingCart, setIsRefreshingCart] = useState(true);

  //custom state
  const [showSingleCartPopup, setShowSingleCartPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [show, setShow] = useState(false);
  const [discountShow, setDiscountShow] = useState(false);
  const [cartTemplateShow, setCartTemplateShow] = useState(false);
  const [popupModalData, setPopupModalData] = useState({});
  const [cartPopupModalData, setCartPopupModalData] = useState({});
  const [popupModalDiscount, setPopupModalDiscount] = useState({});
  const [discountLabel, setDiscountLabel] = useState({});
  const [freeQuantity, setFreeQuantity] = useState({});
  const [discountAmount, setDiscountAmount] = useState(0);
  const [mixProgress, setMixProgress] = useState({
    0: { progress: 0, quantity: 0 },
  });
  const [selectedOffer, setSelectedOffer] = useState([]);

  const handleClose = () => setShow(false);
  const handleDiscountClose = () => setDiscountShow(false);
  const handleCartTemplateClose = () => setCartTemplateShow(false);

  const discountPopup = (data, id) => {
    const rowSearch = data.find((result) => result.PARTNAME == id.PARTNAME);
    setDiscountShow(!discountShow);
    setPopupModalDiscount({ rowSearch });
  };

  console.log(isVendorName, 'isVendorNameee')

  const options = {
    filtering: true,
  };

  const handleCartClick = () => {
    setShowCart(!showCart);
  };

  const handleCartClose = () => {
    setShowCart(false);
    navigate("/app/catalogue");
  };
  const fetchLatestCart = async (isRefresh) => {
    if (!isVendorName || isVendorName === "") {
      setIsLoadingCart(true)
      if(isRefresh){
        setIsRefreshingCart(true)
      }else{
        setIsRefreshingCart(false)
      }
      const userId = localStorage.getItem("userId");
      const response = await fetchSavedTemplate1(userId);
      let parsedCartList = response?.cartData
        ? JSON.parse(response?.cartData)
        : initialState;
      dispatch(setCartDetails(parsedCartList));
    }
  };

  const handleSaveCart = async () => {
    if(isRefreshingCart) return
    if (!isVendorName || isVendorName === "") {
      const userId = localStorage.getItem("userId");
      const body = {
        userId,
        name: "Cart Item",
        type: "cart",
        cartData: JSON.stringify(cartDetails),
        totalItems: cartList.length,
        amount: Number(cartGrossTotal).toFixed(2),
        discount: Number(cartDiscount).toFixed(2),
        quantity: totalCartItem,
      };

      try {
        const savedCartResponse = await postSavedTemplate(body);
        if (savedCartResponse?.id) {
          dispatch(
            setCartDetails({
              ...cartDetails,
              cartDataId: savedCartResponse?.id,
            })
          );
        }
      } catch (error) {
        console.log(error, "Cart Saving error");
      }finally{
        setIsLoadingCart(false)
      }
    }
  };

  // FETCH LATEST CART DATA FROM API BEFORE CHANGING CART
  useEffect(() => {
    if (!isVendorName || isVendorName === "") {
      fetchLatestCart(true);
    } else {
      if (cartList.length > 0) return;
      const cartDetails = localStorage.getItem("kediCartDetails");
      const parsedCartDetails = cartDetails
        ? JSON.parse(cartDetails)
        : initialState;
      dispatch(setCartDetails(parsedCartDetails));
      setIsLoadingCart(false)
    }
  }, [dispatch, isVendorName]);

  useEffect(() => {
    if (!isVendorName || isVendorName === "") {
      handleSaveCart();
    } else {
      localStorage.setItem(
        "kediCartDetails",
        cartDetails ? JSON.stringify(cartDetails) : ""
      );
    }
  }, [cartDetails]);

  const searchParams = new URLSearchParams(window.location.search);
  const cartShowTrue = searchParams.get("cart");

  useEffect(() => {
    if (cartShowTrue) {
      setShowCart(true);
    }
  }, [cartShowTrue]);

  return (
    <>
      <div class="custom-filters">
        <FormControlLabel
          size="small"
          control={
            <MaterialUISwitch
              sx={{ m: 0 }}
              defaultUnchecked
              onChange={showDiscounted}
            />
          }
          label={
            <Typography noWrap fontSize={14}>
              Discounted products
            </Typography>
          }
        />
        <FormControlLabel
          size="small"
          control={
            <MaterialUISwitchQuota
              sx={{ m: 0 }}
              defaultUnchecked
              onChange={showQuota}
            />
          }
          label={
            <Typography noWrap fontSize={14}>
              Products with quota
            </Typography>
          }
        />
        <FormControlLabel
          size="small"
          control={
            <MaterialUISwitchQuota
              sx={{ m: 0 }}
              defaultUnchecked
              onChange={showInStock}
            />
          }
          label={
            <Typography noWrap fontSize={14}>
              Back to stock
            </Typography>
          }
        />
        <FormControlLabel
          size="small"
          control={
            <MaterialUISwitchQuota
              sx={{ m: 0 }}
              defaultUnchecked
              onChange={showInStockExclusive}
            />
          }
          label={
            <Typography noWrap fontSize={14}>
              Back to stock Pharmacy
            </Typography>
          }
        />
        {/* <input
          type="text"
          id="filterInput"
          value={supValue || ""}
          onChange={handleSupplierChange}
          placeholder="Search by importer name..."
        />
        <input
          type="text"
          id="filterInput"
          value={activeIngValue || ""}
          onChange={handleActiveIngredientChange}
          placeholder="Search for Active Substance..."
        /> */}
        <p className="info-text">
          Η ημερομηνία λήξης είναι πάντα η πιο κοντινή. Επιλέξτε{" "}
          <Info
            style={{
              color: "#1f79d5",
              cursor: "pointer",
              fontSize: "15px",
            }}
          />{" "}
          για να δείτε όλες τις ημερομηνίες λήξεις.
        </p>
      </div>
      {isViewerOpen && (
        <ImageViewer
          src={[tagetImage]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 50,
          },
        }}
        columns={columns}
        data={data}
        initialState={{
          showColumnFilters: true,
          density: window.innerWidth > 1024 ? "comfortable" : "standard",
          columnVisibility: columnVisibility,
        }}
        manualFiltering
        options={options}
        manualPagination
        manualSorting
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onColumnVisibilityChange={setColumnVisibility}
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        state={{
          showGlobalFilter: true,
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
          columnVisibility,
        }}
        enableRowActions
        renderRowActions={({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {row.original.hasOwnProperty("discounts") &&
              row.original.discounts.length > 0 && (
                <button
                  className="bg-kedifapgreen-200 hover:bg-kedifapred-700 text-white p-3 rounded-3xl shadow-lg table-cart-button"
                  onClick={() => {
                    discountPopup(data, row.original);
                  }}
                  style={{
                    backgroundColor: "#db2d2d",
                    width: "30px",
                    fontSize: "15px",
                    height: "30px",
                  }}
                >
                  <FaCartArrowDown
                    style={{
                      right: "8px",
                      bottom: "8px",
                      position: "relative",
                    }}
                  />
                </button>
              )}
            {(!row.original.hasOwnProperty("discounts") ||
              row.original.discounts.length === 0) && (
              <button
                className="bg-kedifapgreen-200 hover:bg-kedifapred-700 text-white p-3 rounded-3xl shadow-lg table-cart-button"
                onClick={() => {
                  setSelectedRow(row?.original);
                  setShowSingleCartPopup(true);
                  // const payload = {
                  //   row: row.original,
                  //   setShowCart,
                  //   cartList,
                  //   dispatch,
                  //   singleUnit: true,
                  // };
                  // handleAddToCartFun(payload);
                }}
                style={{
                  width: "30px",
                  fontSize: "15px",
                  height: "30px",
                }}
              >
                <FaCartArrowDown
                  style={{
                    right: "8px",
                    bottom: "8px",
                    position: "relative",
                  }}
                />
              </button>
            )}
            {/* {row.original.hasOwnProperty("discounts") && (
              <FcMoneyTransfer
                style={{
                  display: iconDisplay,
                  fontSize: "30px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  discountPopup(data, row.original);
                }}
              />
            )} */}
            <Info
              className="table-info-button"
              style={{
                color: "#1f79d5",
                cursor: "pointer",
                fontSize: "35px",
              }}
              onClick={() => {
                ProductPopup(
                  data,
                  row.original,
                  show,
                  setShow,
                  setPopupModalData
                );
              }}
            ></Info>
            {row.original.IMGFILENAME !== null && (
              <>
                <div class={"thumbnail parts-preview"}>
                  <ImageSearchIcon
                    onClick={() =>
                      openImageViewer(
                        row.original.PARTNAME,
                        row.original.IMGFILENAME
                      )
                    }
                    className="w-full rounded"
                    key={row.original.PARTNAME}
                    style={{
                      color: "#1f79d5",
                      cursor: "pointer",
                      fontSize: "35px",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        )}
      />
      {showSingleCartPopup && (
        <SingleCartPopupModal
          cartList={cartList}
          dispatch={dispatch}
          row={selectedRow}
          setShowSingleCartPopup={setShowSingleCartPopup}
          showSingleCartPopup={showSingleCartPopup}
          fetchLatestCart={fetchLatestCart}
          isLoadingCart={isLoadingCart}
        />
      )}
      <CartPopupModal
        show={cartTemplateShow}
        handleClose={handleCartTemplateClose}
        cartPopupModalData={cartPopupModalData}
        cartItems={cartItems}
        setCartItems={setCartItems}
        setTotal={setTotal}
        setShowCart={setShowCart}
        quantityInputValue={quantityInputValue}
        setQuantityInputValue={setQuantityInputValue}
      ></CartPopupModal>
      {show && (
        <PopupModal
          show={show}
          handleClose={handleClose}
          popupModalData={popupModalData}
        />
      )}
      {discountShow && (
        <DiscountModal
          show={discountShow}
          handleClose={handleDiscountClose}
          popupModalDiscount={popupModalDiscount}
          cartItems={cartItems}
          setCartItems={setCartItems}
          total={total}
          setTotal={setTotal}
          setShowCart={setShowCart}
          handleAddToCart={handleAddToCartFun}
          quantityInputValue={quantityInputValue}
          setQuantityInputValue={setQuantityInputValue}
          caluclateFlatTotal={caluclateFlatTotal}
          caluclateDiscount={caluclateDiscount}
          setDiscountAmount={setDiscountAmount}
          productQuantity={productQuantity}
          setProductQuantity={setProductQuantity}
          discountLabel={discountLabel}
          setDiscountLabel={setDiscountLabel}
          freeQuantity={freeQuantity}
          setFreeQuantity={setFreeQuantity}
          caluclateMixMatch={caluclateMixMatch}
          highlightStyle={highlightStyle}
          setHighlightStyle={setHighlightStyle}
          mixProgress={mixProgress}
          setMixProgress={setMixProgress}
          selectedOffer={selectedOffer}
          setSelectedOffer={setSelectedOffer}
          fetchLatestCart={fetchLatestCart}
          isLoadingCart={isLoadingCart}
        />
      )}
      <Cart
        showCart={showCart}
        setShowCart={setShowCart}
        cartItems={cartItems}
        setCartItems={setCartItems}
        handleCartClose={handleCartClose}
        handleCartClick={handleCartClick}
        total={total}
        setTotal={setTotal}
        cartTemplate={cartTemplate}
        setCartTemplate={setCartTemplate}
        quantityInputValue={quantityInputValue}
        setQuantityInputValue={setQuantityInputValue}
        caluclateFlatTotal={caluclateFlatTotal}
        caluclateDiscount={caluclateDiscount}
        setDiscountAmount={setDiscountAmount}
        productQuantity={productQuantity}
        setProductQuantity={setProductQuantity}
        discountLabel={discountLabel}
        setDiscountLabel={setDiscountLabel}
        freeQuantity={freeQuantity}
        setFreeQuantity={setFreeQuantity}
        isVendorName={isVendorName}
        caluclateMixMatch={caluclateMixMatch}
        discountAmount={discountAmount}
        highlightStyle={highlightStyle}
        setHighlightStyle={setHighlightStyle}
        mixProgress={mixProgress}
        setMixProgress={setMixProgress}
        setSelectedOffer={setSelectedOffer}
        fetchLatestCart={fetchLatestCart}
        isLoadingCart={isLoadingCart}
      />
      <Toaster containerStyle={{ zIndex: 999999999999999 }} />
    </>
  );
};

export default MaterialTable;
