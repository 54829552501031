import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { pdf } from "@react-pdf/renderer";
import emailjs from "emailjs-com";
import toast from "react-hot-toast";
import moment from "moment";
import { fetchPartsApi } from "../components/Catalogue/Api/partsApi";
import { ReturnProductPdf } from "../components/ReturnProductPdf";
import { dextPolicyObject } from "../constant/dexPolicyObject";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

const ReturnProduct = ({ isVendorName }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchedOverdueProduct, setSearchedOverdueProduct] = useState(null);
  const [searchedNormalProduct, setSearchedNormalProduct] = useState(null);
  const [allInvoices, setAllInvoices] = useState([]);
  const [allReturnProducts, setAllReturnProducts] = useState([]);
  const [boxNumber, setBoxNumber] = useState("");
  const [importerName, setImporterName] = useState("");
  const [importerSignatureURL, setImporterSignatureURL] = useState("");
  const [isLoadingPolicy, setIsLoadingPolicy] = useState(false);
  const [isFetchingInvoice, setIsFetchingInvoice] = useState(false);
  const [currentTab, setCurrentTab] = useState("normal");

  const userFullId = localStorage.getItem("userId");
  const userDesc = localStorage.getItem("userDesc");
  const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");

  console.log(params.get("type"), "params");

  useEffect(() => {
    if (params.get("type")) {
      setCurrentTab(params.get("type"));
    }
  }, [params]);

  const fetchAllInvoices = async (PARTNAME) => {
    try {
      setIsFetchingInvoice(true);
      // Get today's date and the date 15 days ago in the format YYYY-MM-DD
      const today = moment().format("YYYY-MM-DD");
      const fifteenDaysAgo = moment().subtract(15, "days").format("YYYY-MM-DD");

      // Construct the URL with the date range
      const salesReportUrl = `${process.env.REACT_APP_API_URL}/get-sales?id=${userDesc}&PARTCODE=${PARTNAME}&str=${fifteenDaysAgo}&end=${today}`;

      const response = await axios.get(salesReportUrl);
      const filteredData =
        response?.data?.length > 0 &&
        response?.data.filter(
          (item) => item?.total_qty && Number(item?.total_qty || 0) > 0
        );
      if (filteredData?.length > 0) {
        setAllInvoices(filteredData);
      } else {
        setAllInvoices([]);
        window.alert(
          "Δεν υπάρχουν τιμολόγια των τελευταίων 15 ημερών με το προϊόν που επιλέξατε"
        );
      }
    } catch (error) {
      console.error("Error fetching invoices:", error);
    } finally {
      setIsFetchingInvoice(false);
    }
  };

  const fetchReturnPolicy = async (type) => {
    try {
      setIsLoadingPolicy(true);
      setSearchedNormalProduct(null);
      setSearchedOverdueProduct(null);
      // const response = await axios.get(
      //   `${process.env.REACT_APP_API_URL}/return-policy?kdcode=${searchValue}`
      // );
      const filter = [{ id: "DEXT_PARTBARCODE", value: searchValue }];
      const response = await fetchPartsApi(`?globalFilter=${searchValue}`);
      const productData = response?.data[0];

      if (type === "overDue" || type === "recall") {
        setSearchedOverdueProduct(productData);
        setSearchedNormalProduct(null);
      }

      if (type === "normal") {
        setSearchedNormalProduct(productData);
        setSearchedOverdueProduct(null);
        fetchAllInvoices(productData?.PARTNAME);
      }
    } catch (error) {
      console.error("Error fetching return policy:", error);
    } finally {
      setIsLoadingPolicy(false);
    }
  };

  const sendEmail = async (file) => {
    const templateParams = {
      file,
      from_name: userDetails.NAME,
      from_email: "rakesh@spirehubs.com",
      to_email: "rakesh@spirehubs.com",
      message: `<div><h1>Hello, ${userDetails.NAME}!</h1><p>This is a message with HTML content.</p></div>`,
    };
    try {
      await emailjs.send(
        "service_qcjor0n",
        "template_a9i1wy1",
        templateParams,
        "Uf1hsQIllFwhT1YhB"
      );
      console.log("Email successfully sent!");
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const handleSignatureUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImporterSignatureURL(reader.result); // base64 string
      };
      reader.readAsDataURL(file); // convert to base64
    }
  };

  const handleSubmitForm = async () => {
    const invalidAgreements = allReturnProducts.filter(
      (item) => !item.periodPermitted && !item.agreement
    );

    if (invalidAgreements.length > 0) {
      alert(
        "Σύμφωνα με την πολιτική επιστροφών, το προϊόν δεν επιστρέφεται. Με έγκριση Αντιπροσώπου, συμπληρώστε το Special Agreement. Η επιστροφή ισχύει μόνο με υπογραφή και σφραγίδα στο έντυπο."
      );
      return;
    }

    let type = "LE";
    if (currentTab === "recall") {
      type = "AN";
    } else if (currentTab === "normal") {
      type = "KE";
    } else if (currentTab === "overDue") {
      type = "LE";
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/return-products`,
        { user_id: userFullId, products: allReturnProducts, type }
      );
      const pdfBlob = await pdf(
        <ReturnProductPdf
          allReturnProducts={allReturnProducts}
          otherInfo={{
            importerName,
            importerSignatureURL,
            serialNo: response?.data?.product_return_id,
            customer: { userFullId, name: userDetails.FIRM },
            type,
            currentTab,
          }}
        />
      ).toBlob();
      window.open(URL.createObjectURL(pdfBlob), "_blank");
      window.location.reload();
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Server Error");
    }
  };

  const handleAddItem = () => {
    const isAlreadyAdded = allReturnProducts.some(
      (item) => item.PARTNAME === selectedProducts.PARTNAME
    );

    if (isAlreadyAdded) {
      alert("This Part is already added");
      return;
    }

    if (
      !selectedProducts?.qty ||
      !selectedProducts?.lotNo ||
      !selectedProducts?.currentExpiry
    ) {
      alert("Please fill all the field");
      return;
    }

    const searchedProduct = searchedOverdueProduct || searchedNormalProduct;

    const allProductKeys = { ...searchedProduct, ...selectedProducts };

    console.log(allProductKeys, "allProductKeys");

    const currentExpiryDate = allProductKeys.currentExpiry
      ? moment(allProductKeys.currentExpiry, "YYYY-MM-DD") // Specify format
      : null;
    const policyCode = allProductKeys.DEXT_CSPOLICYCODE;

    console.log(policyCode, "policyCode");

    let isPeriodPermitted =
      searchedNormalProduct || currentTab === "recall" ? true : false;
    const today = moment(); // Today's date

    if (
      currentExpiryDate &&
      policyCode &&
      !searchedNormalProduct &&
      currentTab !== "recall"
    ) {
      switch (policyCode) {
        case "3M":
          // Must be today + 3 months onwards
          isPeriodPermitted = currentExpiryDate.isSameOrAfter(
            today.clone().add(3, "months")
          );
          break;

        case "4M":
          // Must be today + 4 months onwards
          isPeriodPermitted = currentExpiryDate.isSameOrAfter(
            today.clone().add(4, "months")
          );
          break;

        case "6M":
          // Must be today + 6 months onwards
          isPeriodPermitted = currentExpiryDate.isSameOrAfter(
            today.clone().add(6, "months")
          );
          break;

        case "6M/exp":
        case "6M/EXP":
          // Always permitted
          isPeriodPermitted = true;
          break;

        case "exp":
        case "EXP":
          // Expiry must be before today
          isPeriodPermitted = currentExpiryDate.isBefore(today);
          break;

        case "NONE":
        case "NULL":
        case "Special":
          // Explicitly not permitted
          isPeriodPermitted = false;
          break;

        default:
          isPeriodPermitted = false;
      }
    }

    setAllReturnProducts((prev) => [
      ...prev,
      {
        ...allProductKeys,
        periodPermitted: isPeriodPermitted,
        isExpired: currentExpiryDate
          ? currentExpiryDate.isBefore(today)
          : false,
      },
    ]);

    setSelectedProducts({});
    setSearchValue("");
    setSearchedNormalProduct(null);
    setSearchedOverdueProduct(null);
  };

  const handleDeleteItem = (index) => {
    setAllReturnProducts((prev) => {
      const updatedProducts = [...prev];
      updatedProducts.splice(index, 1);
      return updatedProducts;
    });
  };

  useEffect(() => {
    if (userFullId && userFullId.includes("V")) {
      navigate("/app");
    }
  }, [userFullId]);

  const handleDifferentForm = (type) => {
    if (allReturnProducts?.length === 0) {
      navigate(`/app/return-product?type=${type}`);
    } else if (window.confirm("Οι αλλαγές σας θα χαθούν. Είσαστε σίγουροι")) {
      setAllReturnProducts([]);
      navigate(`/app/return-product?type=${type}`);
    }
  };

  console.log(allReturnProducts, "allReturnProducts");

  return (
    <div>
      <div className="flex justify-between">
        <Typography variant="h4" align="center" fontSize={25} gutterBottom>
          Return form {currentTab === "recall" && "Ανακλήσεις"}{" "}
          {currentTab === "normal" && "Επιστροφές 15 ημερών"}{" "}
          {currentTab === "overDue" && "Ληξιπρόθεσμες επιστροφές"}
        </Typography>

        {(userFullId === "S9999" || isVendorName == "") && (
          <div className="flex gap-3">
            {currentTab !== "recall" && (
              <Button
                variant="contained"
                onClick={() => {
                  handleDifferentForm("recall");
                }}
              >
                Ανακλήσεις
              </Button>
            )}
            {currentTab !== "normal" && (
              <Button
                variant="contained"
                onClick={() => {
                  handleDifferentForm("normal");
                }}
              >
                Επιστροφές 15 ημερών
              </Button>
            )}
            {currentTab !== "overDue" && (
              <Button
                variant="contained"
                onClick={() => {
                  handleDifferentForm("overDue");
                }}
              >
                Ληξιπρόθεσμες επιστροφές
              </Button>
            )}
            <Link to="/app/return-product-list">
              <Button variant="outlined">History</Button>
            </Link>
          </div>
        )}
      </div>

      {/* Return Form Table */}
      <div className="overflow-x-auto my-3">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              {[
                "IV",
                "CODE",
                "Description",
                "Return Policy",
                "Exp. Date",
                "Lot No.",
                "QTY",
                "Vendor Agreement",
              ].map((header, index) => (
                <th key={index} className="p-3 border-gray-300">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allReturnProducts.map((item, index) => (
              <tr key={index} className="border-b border-gray-300">
                <td className="p-3">
                  {item?.IV || "N/A"}
                  <br />
                  <Button
                    color="error"
                    onClick={() => {
                      handleDeleteItem(index);
                    }}
                  >
                    Remove
                  </Button>
                </td>
                <td className="p-3">{item.PARTNAME}</td>
                <td className="p-3">{item.PARTDES}</td>
                <td className="p-3">
                  {dextPolicyObject[item.DEXT_CSPOLICYCODE]}
                </td>
                <td className="p-3">{item?.currentExpiry || "N/A"}</td>
                <td className="p-3">{item?.lotNo}</td>
                <td className="p-3">{item?.qty}</td>
                <td className="p-3">
                  {!item.periodPermitted ? (
                    <Checkbox
                      onChange={(e) => {
                        const updatedProducts = [...allReturnProducts];
                        updatedProducts[index] = {
                          ...updatedProducts[index],
                          agreement: e.target.checked,
                        };
                        setAllReturnProducts(updatedProducts);
                      }}
                    />
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Form Inputs */}
      <div className="flex flex-col gap-3 my-3">
        <TextField
          label="Enter Kedifap Code/PARTNAME/BARCODE"
          fullWidth
          size="small"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          sx={{ maxWidth: 300 }}
        />

        <div className="flex gap-3">
          <Button
            variant="contained"
            onClick={() => fetchReturnPolicy(currentTab)}
            disabled={isLoadingPolicy || !searchValue}
          >
            SUBMIT
          </Button>
        </div>
        {isLoadingPolicy && <div className="py-1">Loading...</div>}

        {searchedOverdueProduct && (
          <div className="flex flex-col gap-2">
            <p>{searchedOverdueProduct.PARTDES}</p>
          </div>
        )}
        {searchedNormalProduct && (
          <div className="flex flex-col gap-2">
            <p>{searchedNormalProduct.PARTDES}</p>
            {/* <p>{dextPolicyObject[searchedNormalProduct.DEXT_CSPOLICYCODE]}</p> */}
          </div>
        )}
        {(searchedNormalProduct || searchedOverdueProduct) &&
          currentTab === "overDue" && (
            <div className="flex flex-col gap-2">
              <p>
                {dextPolicyObject[searchedOverdueProduct.DEXT_CSPOLICYCODE]}
              </p>
            </div>
          )}
      </div>

      {/* Add Product Section */}
      {(searchedOverdueProduct || searchedNormalProduct) && (
        <div className="p-3 rounded-md flex flex-col justify-between items-center bg-slate-100">
          <Typography fontWeight={700}>Add Product in Return Form:</Typography>

          {isFetchingInvoice && (
            <div className="flex items-center w-full gap-2 mt-3">
              Loading...
            </div>
          )}

          {!isFetchingInvoice && searchedNormalProduct && (
            <div className="w-full">
              {allInvoices && allInvoices?.length > 0 ? (
                <div className="flex items-center w-full gap-2 mt-3">
                  {searchedNormalProduct && allInvoices?.length === 0 && (
                    <Autocomplete
                      size="small"
                      disablePortal
                      options={allInvoices}
                      getOptionLabel={(option) => option.IV || ""}
                      value={selectedProducts || null}
                      onChange={(event, newValue) =>
                        setSelectedProducts(newValue || {})
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Invoice"
                          sx={{ minWidth: 200 }}
                        />
                      )}
                    />
                  )}
                  {searchedNormalProduct &&
                    allInvoices &&
                    allInvoices?.length > 0 && (
                      <TextField
                        size="small"
                        placeholder="Invoice no."
                        value={selectedProducts.IV || ""}
                        onChange={(e) =>
                          setSelectedProducts((prev) => ({
                            ...prev,
                            IV: e.target.value,
                          }))
                        }
                      />
                    )}
                  <TextField
                    size="small"
                    type="number"
                    placeholder="Qty"
                    value={selectedProducts.qty || ""}
                    onChange={(e) =>
                      setSelectedProducts((prev) => ({
                        ...prev,
                        qty: +e.target.value,
                      }))
                    }
                  />
                  <TextField
                    size="small"
                    placeholder="Lot no."
                    value={selectedProducts.lotNo || ""}
                    onChange={(e) =>
                      setSelectedProducts((prev) => ({
                        ...prev,
                        lotNo: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    size="small"
                    type="date"
                    placeholder="Expiry date"
                    value={selectedProducts.currentExpiry || ""}
                    onChange={(e) =>
                      setSelectedProducts((prev) => ({
                        ...prev,
                        currentExpiry: e.target.value,
                      }))
                    }
                  />
                  <Button variant="contained" onClick={handleAddItem}>
                    Add
                  </Button>
                </div>
              ) : (
                <p>Not found any invoice</p>
              )}
            </div>
          )}
          {!isFetchingInvoice &&
            searchedOverdueProduct &&
            currentTab !== "recall" && (
              <div className="w-full">
                <div className="flex items-center w-full gap-2 mt-3">
                  <TextField
                    size="small"
                    type="number"
                    placeholder="Qty"
                    value={selectedProducts.qty || ""}
                    onChange={(e) =>
                      setSelectedProducts((prev) => ({
                        ...prev,
                        qty: +e.target.value,
                      }))
                    }
                  />
                  <TextField
                    size="small"
                    placeholder="Lot no."
                    value={selectedProducts.lotNo || ""}
                    onChange={(e) =>
                      setSelectedProducts((prev) => ({
                        ...prev,
                        lotNo: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    size="small"
                    type="date"
                    placeholder="Expiry date"
                    value={selectedProducts.currentExpiry || ""}
                    onChange={(e) =>
                      setSelectedProducts((prev) => ({
                        ...prev,
                        currentExpiry: e.target.value,
                      }))
                    }
                  />
                  <Button variant="contained" onClick={handleAddItem}>
                    Add
                  </Button>
                </div>
              </div>
            )}
          {!isFetchingInvoice &&
            searchedOverdueProduct &&
            currentTab === "recall" && (
              <div className="w-full">
                <div className="flex items-center w-full gap-2 mt-3">
                  <TextField
                    size="small"
                    type="number"
                    placeholder="Qty"
                    value={selectedProducts.qty || ""}
                    onChange={(e) =>
                      setSelectedProducts((prev) => ({
                        ...prev,
                        qty: +e.target.value,
                      }))
                    }
                  />
                  <TextField
                    size="small"
                    placeholder="Lot no."
                    value={selectedProducts.lotNo || ""}
                    onChange={(e) =>
                      setSelectedProducts((prev) => ({
                        ...prev,
                        lotNo: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    size="small"
                    type="date"
                    placeholder="Expiry date"
                    value={selectedProducts.currentExpiry || ""}
                    onChange={(e) =>
                      setSelectedProducts((prev) => ({
                        ...prev,
                        currentExpiry: e.target.value,
                      }))
                    }
                  />
                  <Button variant="contained" onClick={handleAddItem}>
                    Add
                  </Button>
                </div>
              </div>
            )}
        </div>
      )}

      {/* Submit Button */}
      <div className="flex flex-col gap-3 items-center mt-4">
        {/* <div className="flex gap-3">
          <TextField
            label="Importer name"
            placeholder="Name"
            size="small"
            value={importerName}
            onChange={(e) => {
              setImporterName(e.target.value);
            }}
          />
          <TextField
            label="Importer signature"
            size="small"
            type="file"
            inputProps={{ accept: "image/*" }}
            InputLabelProps={{ shrink: true }}
            onChange={handleSignatureUpload}
          />
        </div> */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmitForm}
          disabled={allReturnProducts?.length === 0}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ReturnProduct;
